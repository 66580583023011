<svg-icon [src]="logo" svgClass="logo w-100" svgAriaLabel="Logomarca do Portugol Webstudio" />

<nav>
  <button mat-ripple (click)="newTab.emit()">
    <svg-icon src="assets/mdi/file-plus-outline.svg" svgClass="text-secondary" svgAriaLabel="Ícone de novo arquivo" />
    <span>Novo Arquivo</span>
  </button>

  <button mat-ripple (click)="fileInput.click()">
    <svg-icon
      src="assets/mdi/folder-open-outline.svg"
      svgClass="text-primary"
      svgAriaLabel="Ícone de pasta para ação de abrir arquivo"
    />

    <span>Abrir Arquivo</span>
    <input #fileInput type="file" hidden multiple accept=".por,text/plain" (change)="openFile($event)" />
  </button>

  <button mat-ripple (click)="openExamplesDialog()">
    <svg-icon
      src="assets/mdi/book-open-page-variant-outline.svg"
      svgClass="text-primary"
      svgAriaLabel="Ícone de livro para ação de abrir exemplo"
    />

    <span>Abrir Exemplo</span>
  </button>

  <button mat-ripple (click)="help.emit()">
    <svg-icon src="assets/mdi/help.svg" svgClass="text-blue" alt="Ícone de ajuda" />
    <span>Ajuda</span>
  </button>

  <a
    href="https://github.com/dgadelha/Portugol-Webstudio/discussions/categories/ideias-e-sugest%C3%B5es"
    target="_blank"
    rel="external noreferrer noopener nofollow"
    mat-ripple
    (click)="gaService.event('suggest_features', 'Aba Inicial', 'Enviar sugestões')"
  >
    <svg-icon
      src="assets/mdi/lightbulb-on-outline.svg"
      svgClass="text-secondary"
      svgAriaLabel="Ícone de lâmpada para ação de enviar sugestões"
    />

    <span>Sugestões</span>
  </a>

  <a
    href="https://github.com/dgadelha/Portugol-Webstudio/discussions/categories/d%C3%BAvidas-perguntas"
    target="_blank"
    rel="external noreferrer noopener nofollow"
    mat-ripple
    (click)="gaService.event('ask_questions', 'Aba Inicial', 'Enviar dúvidas')"
  >
    <svg-icon
      src="assets/mdi/comment-question-outline.svg"
      svgClass="text-blue"
      svgAriaLabel="Ícone de balão de diálogo para ação de enviar dúvidas"
    />

    <span>Dúvidas e Perguntas</span>
  </a>

  <button mat-ripple (click)="openSettingsDialog()" class="with-badge-new">
    <svg-icon
      src="assets/mdi/cog-outline.svg"
      svgClass="text-primary"
      svgAriaLabel="Ícone de engrenagem para ação de abrir configurações"
    />

    <span>Configurações</span>
  </button>

  <button mat-ripple (click)="openAboutDialog()" class="with-badge-new">
    <svg-icon
      src="assets/mdi/information-outline.svg"
      svgClass="text-blue"
      svgAriaLabel="Ícone de informação para ação de abrir sobre"
    />

    <span>Sobre</span>
  </button>
</nav>

<section class="info">
  <h4><svg-icon src="assets/mdi/newspaper.svg" svgAriaLabel="Ícone de jornal para notícias" />Novidades</h4>
  <p><strong>09/02/2025:</strong> Refatoração/correção de variáveis por referência.</p>
  <p>
    <strong>08/02/2025:</strong> Implementação da biblioteca <code>Graficos</code> e correção na execução de operações
    lógicas.
  </p>
  <p>
    <strong>04/01/2025:</strong> Configurações de exibição do editor de código adicionadas, agora é possível alterar o
    tamanho da fonte e a quebra de linha. Nova opção para escolher o local de salvar o arquivo (somente no Chrome ou
    Edge). Melhorias na exibição de erros.
  </p>
</section>

<footer>
  <summary>Versão: <span matTooltip="09/02/2025 02:35:23 PM UTC">c216b83-BETA</span></summary>
</footer>
